<template>
    <div class="d-flex flex-column fill-height">
        <v-col class="pa-0 ma-0 mb-auto">
            <v-row no-gutters>
                <v-col class="col-9-5 d-flex flex-column pt-3">
                    <div>
                        <span class="header-title">{{ Office__title  }}</span>
                        <span class="header-subtitle font-weight-bold" style="vertical-align: top" v-if="Office__companyno != ''">{{ '(' + Office__companyno + ')' }}</span>
                    </div>
                    <span class="header-subtitle pt-1 pb-0">{{ Office__address1  }}</span>
                    <span class="header-subtitle pt-1 pb-0">{{ [Office__address2, Office__city, Office__state, Office__country].join(', ')  }}</span>
                    <span class="header-subtitle pt-1 pb-0">{{ $t('message.tel') + ': ' + Office__invoicetel  }}</span>
                    <span class="header-subtitle pt-1 pb-0">{{ $t('message.email') + ': ' + Office__invoiceemail + ' ' + $t('message.website') + ': ' + Office__website }}</span>
                </v-col>
                <v-col class="col-2-5 text-right">
                    <img
                        :src="appLogo"
                        class="mb-3 mr-3"
                        width="130px"
                    />
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="text-center text-uppercase border-top-2 border-bottom-2 pa-0 font-weight-bold document-caption">{{ $t('message.coverNote') }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="col-4-5">
                    <v-row no-gutters>
                        <v-col cols="2" class="font-weight-bold">{{ $t('message.buyer') }}:</v-col>
                        <v-col cols="9" class="d-flex flex-column">
                            <span>{{ Customer__title }}</span>
                            <span>{{ Customer__address1 }}</span>
                            <span>{{ Customer__address2 }}</span>
                            <span>{{ Customer__address3 }}</span>
                            <span>{{ Customer__city + ' ' + Customer__postcode}}</span>
                            <span>{{ Customer__state }}</span>
                            <span>{{ Customer__country }}</span>
                            <span v-if="[4].includes(Customer__country_id)">NPWP No</span>
                            <span v-if="[5].includes(Customer__country_id)">USCC</span>
                            <span v-if="[4].includes(Customer__country_id)">{{ Customer__gstno }}</span>
                            <span v-if="[5].includes(Customer__country_id)">{{ Customer__uscc }}</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="col-4-5">
                    <v-row no-gutters>
                        <v-col cols="5" class="font-weight-bold">{{ $t('message.vesselName') }}:</v-col>
                        <v-col cols="7">{{ Contract__shipmentstatus_text }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="5" class="font-weight-bold">{{ $t('message.etd') }}:</v-col>
                        <v-col cols="7">{{ Contract__etddate != '0000-00-00' ? formatDate(Contract__etddate) : '' }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="5" class="font-weight-bold">{{ $t('message.eta') }}:</v-col>
                        <v-col cols="7">{{ Contract__etadate != '0000-00-00' ? formatDate(Contract__etadate) : '' }}</v-col>
                    </v-row>
                    <v-row no-gutters class="align-center">
                        <v-col cols="5" class="font-weight-bold">{{ $t('message.noOfContainers') }}:</v-col>
                        <v-col cols="7">{{ Contract__containercount }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="5" class="font-weight-bold">{{ $t('message.containerNos') }}:</v-col>
                        <v-col cols="7" class="d-flex flex-column">
                            <span v-for="(container,index) in Contract__containers" v-bind:key="index">{{ container.container }}</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="3">
                    <v-row no-gutters>
                        <v-col cols="5" class="font-weight-bold">{{ $t('message.date') }}:</v-col>
                        <v-col cols="7">{{ CoverNote__ttadate != '0000-00-00' ? formatDate(CoverNote__ttadate) : '' }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="5" class="font-weight-bold">{{ $t('message.coverNote') }}:</v-col>
                        <v-col cols="7">{{ CoverNote__title }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="5" class="font-weight-bold">{{ $t('message.contractNo') }}:</v-col>
                        <v-col cols="7">{{ Contract__version != null ? [Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-') : Contract__title }}</v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-simple-table
                class="specification-table mt-4"
                dense
            >
                <template v-slot:default>
                    <tr class="table-header">
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1">{{ $t('message.descriptionAndSize') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 width-10-pct">{{ $t('message.grade') }}</td>
                        <td class="px-0 font-weight-bold text-right width-12-pct border-bottom-1 border-top-1">{{ $t('message.quantity') }}</td>
                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1">
                            <div class="d-flex flex-column">
                                <span>{{ $t('message.price') }}</span>
                                <span v-if="Invoice__currency_id != null">{{ currencies.find((currency) => currency.Currency.id == Invoice__currency_id).Currency.code }}</span>
                                <span v-else>{{ currencies.find((currency) => currency.Currency.id == Contract__currency_id).Currency.code }}</span>
                            </div>
                        </td>
                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1">
                            <div class="d-flex flex-column">
                                <span>{{ $t('message.total') }}</span>
                                <span v-if="Invoice__currency_id != null">{{ currencies.find((currency) => currency.Currency.id == Invoice__currency_id).Currency.code }}</span>
                                <span v-else>{{ currencies.find((currency) => currency.Currency.id == Contract__currency_id).Currency.code }}</span>
                            </div>
                        </td>
                    </tr>
                    <template v-for="(item,index) in Salesitems" v-if="item.type == 'default'">
                        <tr class="table-row-main" v-if="index == 0 ">
                            <td colspan="5">{{ item.Salesdescription.title }}</td>
                        </tr>
                        <tr class="table-row" v-else-if="index > 0 && Salesitems[index - 1].Salesdescription.title != item.Salesdescription.title">
                            <td colspan="5">{{ item.Salesdescription.title }}</td>
                        </tr>
                        <tr class="table-row-sub">
                            <td class="px-0">{{ item.Size.title }}</td>
                            <td class="px-0">{{ item.Grade.title }}</td>
                            <td class="px-0 text-right">{{ item.type != 'credit' ? formatThisNumber((Contract__salestype_id == 2 ? item.Salesitem.itemqty : item.Salesitem.invqty),uofmPrecision(item.Measurement.title)) + ' ' + uofmQty.find((uofm)=>uofm.Measurement.id == item.Salesitem.measurement_id).Measurement.title : '' }}</td>
                            <td class="px-0 text-right">{{ item.type != 'credit' ? formatThisNumber((Contract__salestype_id == 2 ? item.Salesitem.unitprice : item.Salesitem.invprice),[5].includes(Contract__salestype_id) ? contractCurrencyFormatPrecision : currencyFormatPrecision) : '' }}</td>
                            <td class="px-0 text-right">{{ item.type != 'credit' ? formatThisNumber((Contract__salestype_id == 2 ? item.Salesitem.amount : item.Salesitem.invoiceamount),[5].includes(Contract__salestype_id) ? contractCurrencyFormatPrecision : currencyFormatPrecision) : '(' + formatThisNumber(item.Salesitem.invoiceamount,currencyFormatPrecision) + ')' }}</td>
                        </tr>
                    </template>
                    <template v-else-if="item.type == 'othercharge'">
                        <tr>
                            <td class="px-0">{{ item.Salesitem.size }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="px-0 text-right">{{ formatThisNumber(item.Salesitem.amount,currencyFormatPrecision) }}</td>
                        </tr>
                    </template>
                </template>
            </v-simple-table>
        </v-col>
        <div class="px-0 pb-0 pt-3 ma-0 mt-auto">
            <v-simple-table
                class="specification-table"
                dense
            >
                <template v-slot:default>
                    <tr v-if="CoverNote__remark != null || Contract__fsc != 0">
                        <td colspan="5">
                            <div class="d-flex flex-column">
                                <span class="font-weight-bold py-0 my-0">{{ $t('message.remarks') }}:</span>
                                <span style="white-space: pre-line" v-if="CoverNote__remark != null">
                                    {{ CoverNote__remark != null ? CoverNote__remark.trim() : '' }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5">
                            <v-row no-gutters>
                                <v-col cols="12" class="d-flex flex-column">
                                    <span>{{ Contract__fsc != 0 ? $t('message.certCode') + ' : ' + certifications.find((certification)=>certification.Certification.id == Contract__fsc).Certification.cert_no : '' }}</span>
                                    <span>{{ Contract__fsc != 0 ? $t('message.expiryDate') + ' : ' + certifications.find((certification)=>certification.Certification.id == Contract__fsc).Certification.cert_expiry_date : '' }}</span>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                    <tr>
                        <td class="pa-0 font-weight-bold border-top-1 border-bottom-1"></td>
                        <td class="pa-0 font-weight-bold border-top-1 border-bottom-1"></td>
                        <td class="pa-0 font-weight-bold border-top-1 border-bottom-1"></td>
                        <td class="pa-0 font-weight-bold text-right width-12-pct border-top-1 border-bottom-1">
                            <span v-for="(total,index) in Salesitems__itemsTotalQty" class="text-no-wrap">
                                {{ formatThisNumber(total.Total.qty, uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                            </span>
                        </td>
                        <td class="pa-0 font-weight-bold text-right width-10-pct border-top-1 border-bottom-1">{{ $t('message.totalDue') }}</td>
                        <td class="pa-0 font-weight-bold text-right width-10-pct border-top-1 border-bottom-1">{{ formatThisNumber(Salesitems__itemsTotalAmount,[5].includes(Contract__salestype_id) ? contractCurrencyFormatPrecision : currencyFormatPrecision) }}</td>
                    </tr>
                </template>
            </v-simple-table>
            <v-row no-gutters class="pt-2" v-if="Contract__showbank">
                <v-col cols="12">
                    <v-row no-gutters>
                        <v-col cols="12" class="font-weight-bold">{{ $t('message.pleaseRemitTo') }}:</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__beneficiary != ''">
                        <v-col cols="2">{{ $t('message.beneficiaryName') }}:</v-col>
                        <v-col cols="9">{{ Bank__beneficiary }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__account != ''">
                        <v-col cols="2">{{ $t('message.account') }}:</v-col>
                        <v-col cols="9">{{ Bank__account }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__title != ''">
                        <v-col cols="2">{{ $t('message.bank') }}:</v-col>
                        <v-col cols="9">{{ Bank__title }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__swiftcode != ''">
                        <v-col cols="2">{{ $t('message.swiftId') }}:</v-col>
                        <v-col cols="9">{{ Bank__swiftcode }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__address != ''">
                        <v-col cols="2">{{ $t('message.bankAddress') }}:</v-col>
                        <v-col cols="9">{{ Bank__address }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__agent != ''">
                        <v-col cols="2">{{ $t('message.agentBank') }}:</v-col>
                        <v-col cols="9">{{ Bank__agent }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__agentswiftcode != ''">
                        <v-col cols="2">{{ $t('message.swiftId') }}:</v-col>
                        <v-col cols="9">{{ Bank__agentswiftcode }}</v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.notes') + ':' }}</v-col>
                <v-col cols="12" v-for="footerNote in coverNoteFooterNotes" :key="footerNote.id">
                    <div class="d-flex flex-row align-stretch">
                        <span class="px-1">-</span>
                        <span class="footer-note text-wrap">{{ footerNote.text }}</span>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="text-center font-weight-bold">{{ $t('message.documentElectronicallyGenerated') }}</v-col>
            </v-row>
        </div>
        <h1 id="loaded" v-if="loaded" style="margin-bottom: 0px !important;"></h1>
    </div>
</template>

<script>
    import AppConfig from "Constants/AppConfig";
    import {formatDate, isNumeric, numberFormat} from "../../../helpers/helpers";
    import {api} from "../../../api";
    import {
        certifications,
        containerSizes,
        coverNoteFooterNotes,
        currencies,
        uofm
    } from "../../../store/modules/appic/constants";
    export default {
        name: "PrintCoverNote",
        props: ['coverNoteId'],
        data() {
            return {
                appLogo: AppConfig.appSessionLogo,
                certifications: certifications,
                coverNoteFooterNotes: coverNoteFooterNotes,
                currencies: currencies,
                loaded: false,
                uofmQty: uofm.qty,
                Bank__address: null,
                Bank__agent: null,
                Bank__agentswiftcode: null,
                Bank__id: null,
                Bank__title: null,
                Bank__account: null,
                Bank__swiftcode: null,
                Bank__beneficiary: null,
                Contract__containercount: null,
                Contract__containers: [],
                Contract__etadate: null,
                Contract__etddate: null,
                Contract__fsc: 0,
                Contract__id: null,
                Contract__currency_id: 2,
                Contract__partial_no: null,
                Contract__revision_no: null,
                Contract__salestype_id: 1,
                Contract__shipmentstatus_text: null,
                Contract__showbank: null,
                Contract__title: null,
                Contract__version: null,
                CoverNote__contract_id: null,
                CoverNote__remark: null,
                CoverNote__title: null,
                CoverNote__ttadate: null,
                Customer__title: null,
                Customer__address1: null,
                Customer__address2: null,
                Customer__address3: null,
                Customer__city: null,
                Customer__country: null,
                Customer__country_id: null,
                Customer__gstno: null,
                Customer__postcode: null,
                Customer__state: null,
                Customer__uscc: null,
                Invoice__currency_id: 2,
                Office__title: null,
                Office__address1: null,
                Office__address2: null,
                Office__city: null,
                Office__companyno: null,
                Office__country: null,
                Office__invoiceemail: null,
                Office__invoicetel: null,
                Office__postcode: null,
                Office__state: null,
                Office__stamp: null,
                Office__stamp_ws: null,
                Office__website: null,
                Origin__name: null,
                Salesitems: [],
                Salesitems__itemsTotalAmount: 0,
                Salesitems__itemsTotalQty: [],
            }
        },
        computed: {
            contractCurrencyFormatPrecision () {
                return this.currencies.find((currency) => currency?.Currency?.id === this.Contract__currency_id)?.Currency?.formatPrecision
            },
            currencyFormatPrecision () {
                if(this.Invoice__currency_id != null) {
                    return this.currencies.find((currency) => currency?.Currency?.id === this.Invoice__currency_id)?.Currency?.formatPrecision
                } else {
                    return this.currencies.find((currency) => currency?.Currency?.id === this.Contract__currency_id)?.Currency?.formatPrecision
                }
            },
        },
        methods: {
            formatDate,
            formatThisNumber(value, format) {
                return numberFormat(value, format)
            },
            loadCoverNoteById (covernote_id) {
                let self = this
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/covernotes/" + covernote_id,{
                        })
                        .then(response => {
                            for(let key in response.data.data[0]){
                                self[key] = response.data.data[0][key]
                            }
                            resolve(covernote_id)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            loadSalesItemsByContract (contract_id) {
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/contracts/" + contract_id + '/salesitems',{
                        })
                        .then(response => {
                            this.Salesitems = response.data.data
                            resolve(contract_id)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            async updateTotals () {
                let amount = 0, amount_converted = 0, quantities = []
                await this.Salesitems.forEach((item) => {
                    if(item.type == 'credit') {
                        if(this.Contract__salestype_id == 2){
                            amount -= isNumeric(item.Salesitem.amount) ? parseFloat(item.Salesitem.amount) : 0;
                        } else {
                            amount -= isNumeric(item.Salesitem.invoiceamount) ? parseFloat(item.Salesitem.invoiceamount) : 0;
                        }
                    } else {
                        if(this.Contract__salestype_id == 2){
                            amount += isNumeric(item.Salesitem.amount) ? parseFloat(item.Salesitem.amount) : 0;
                        } else {
                            amount += isNumeric(item.Salesitem.invoiceamount) ? parseFloat(item.Salesitem.invoiceamount) : 0;
                        }
                    }

                    let idx = quantities.findIndex(t=>t.Total.uofm == item.Measurement.title);
                    if(idx !== -1){
                        if(this.Contract__salestype_id == 2){
                            quantities[idx]['Total']['qty'] += isNumeric(item.Salesitem.itemqty) ? parseFloat(item.Salesitem.itemqty) : 0;
                        } else {
                            quantities[idx]['Total']['qty'] += isNumeric(item.Salesitem.invqty) ? parseFloat(item.Salesitem.invqty) : 0;
                        }
                    } else {
                        if(item.type == 'default') {
                            let obj = {'Total': {}}
                            obj['Total']['uofm'] = item.Measurement.title;
                            if(this.Contract__salestype_id == 2){
                                obj['Total']['qty'] = isNumeric(item.Salesitem.itemqty) ? parseFloat(item.Salesitem.itemqty) : 0;
                            } else {
                                obj['Total']['qty'] = isNumeric(item.Salesitem.invqty) ? parseFloat(item.Salesitem.invqty) : 0;
                            }
                            quantities.push(obj)
                        }
                    }
                })
                this.Salesitems__itemsTotalAmount = amount
                this.Salesitems__itemsTotalQty = quantities
            },
            uofmPrecision (uofm) {
                return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
            },
        },
        created() {
            if(this.$route.matched.some(({name}) => name === 'print_covernote')){
                this.loadCoverNoteById(this.coverNoteId)
                    .then(()=>{
                        this.loadSalesItemsByContract(this.Contract__id).then(()=>{
                            this.updateTotals().then(()=>{
                                this.loaded = true
                            })
                        })
                    })
            }
        }
    }
</script>

<style>
    @font-face {
        font-family: 'AppicArial';
        src: url('/static/fonts/Arial.woff2') format('woff2'),
        url('/static/fonts/Arial.woff2') format('woff'),
        url('/static/fonts/Arial.woff2') format('truetype');
    }
    .border-top-1 {
        border-top: 1px solid black !important;
    }
    .border-bottom-1 {
        border-bottom: 1px solid black !important;
    }
    .border-top-2 {
        border-top: 2px solid black;
    }
    .border-bottom-2 {
        border-bottom: 2px solid black !important;
    }
    .col-2-5 {
        flex: 0 0 20.83333333%;
        max-width: 20.83333333%;
    }
    .col-3-5 {
        flex: 0 0 29.16666667%;
        max-width: 29.16666667%;
    }
    .col-4-5 {
        flex: 0 0 37.5%;
        max-width: 37.5%;
    }
    .col-9-5 {
        flex: 0 0 79.16666667%;
        max-width: 79.16666667%;
    }
    .document-caption {
        font-size: 16px !important;
        line-height: 18px;
    }
    .footer-note {
        font-size: 8pt !important;
    }
    .header-title {
        font-size: 20pt !important;
        line-height: 25px;
        font-weight: bold !important;
    }
    .header-subtitle {
        font-size: 10.5pt !important;
    }
    .specification-table table tr td {
        font-family: 'AppicArial' !important;
        line-height:15px !important;
        font-size:9pt !important;
        padding:2px !important;
    }
    .specification-table table tr.table-header td {
        font-family: 'AppicArial' !important;
        line-height:15px !important;
        font-size:9pt !important;
        padding:0px !important;
        height: 15px !important;
        vertical-align: top !important;
    }
    .specification-table table tr.table-row-main td {
        font-family: 'AppicArial' !important;
        line-height:20px !important;
        font-size:9pt !important;
        padding:0px !important;
        height: 20px !important;
    }
    .specification-table table tr.table-row-sub td {
        font-family: 'AppicArial' !important;
        line-height:15px !important;
        font-size:9pt !important;
        padding:0px !important;
        height: 15px !important;
    }
    .specification-table table tr td.table-total {
        height: 8px !important;
    }
    .v-application{
        font-family: 'AppicArial' !important;
        line-height:15px !important;
        font-size:9pt !important;
        padding:2px !important;
    }
    body {
        width: 18.5cm !important;
        margin: 0 !important;
    }
</style>